import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import TopHero from "../components/tophero"
import RepeatingIcons from "../components/repeatingicons"
import AgencyOverview from "../components/agencyoverview"
import SEO from "../components/seo"

class PageTemplate extends Component {

  componentDidMount() {
    // window.seamlessVariables = {url:"https://pwcgov.seamlessdocs.com"}
  }

  render() {
    const landingPage = this.props.data.allNodeLandingPage.edges[0].node
    var groupMenu = this.props.data.allMenuLinkContentMenuLinkContent
    const currentPage = this.props.data.nodePage
    const allAlerts = this.props.data.allNodeAlert
    const blockWithPools = this.props.data.allBlockContentPools
    const contactPage = this.props.data.allNodeContactPage
    const meta = currentPage.relationships.field_meta_data

    return (
        <Layout >
        <SEO
            title={currentPage.title}
            contentBody={currentPage.body}
            metaData={meta}
            locationOrigin={this.props.location.origin + currentPage.path.alias}
            domen={this.props.location.origin}
        />

        <div className={"sub-landing_page pools node-"+currentPage.drupal_internal__nid}>
          {landingPage.relationships.field_hero ? (
            <TopHero landing={landingPage} menu={groupMenu} islanding="0" alerts={allAlerts} contactPage={contactPage} />
          ) : (
            null
          )}

          <section className="breadcrumb">
            <div className="container">
              <ul className="uk-breadcrumb breadcrumb-list">
                <li><Link to="/">Home</Link></li>
                {landingPage.relationships.field_parent_agency !== null && landingPage.relationships.field_parent_agency.relationships.field_hero.field_title_html ? (
                  <li><Link to={landingPage.relationships.field_parent_agency.path.alias}>{landingPage.relationships.field_parent_agency.relationships.field_hero.field_title_html.value}</Link></li>
                ) : (
                  null
                )}
                <li><Link to={landingPage.path.alias}>{landingPage.relationships.field_hero.field_title_html.value}</Link></li>
                <li className="uk-disabled">{currentPage.title}</li>
              </ul>
            </div>
          </section>

          {currentPage.body && currentPage.body.processed ? (
            <section className="body-content">
              <div className="container">
                <div className="entry-content">
                  <div dangerouslySetInnerHTML={{ __html: currentPage.body.processed }} />
                </div>
              </div>
            </section>

          ) : (
            null
          )}

          <section className="info-tabs">
            <div className="container">
              {blockWithPools.edges[0].node.relationships.field_tab_content ? (
                <ul className="uk-grid uk-child-width-auto@m uk-subnav uk-subnav-pill uk-flex tab-bar" data-uk-grid data-uk-switcher="animation: uk-animation-fade">
                  {blockWithPools.edges[0].node.relationships.field_tab_content.map((tab,key) => (
                    <li key={"tab"+key}><a className="departments tab-link" href="#" >{tab.field_title}</a></li>
                  ))}
                </ul>
              ) : (
                null
              )}
              <div className="uk-switcher tab-content">
                {blockWithPools.edges[0].node.relationships.field_tab_content.map((tab,key) => (
                  <div className="single-tab-content" key={key}>
                    {tab.field_description_wysiwyg ? (
                      <div className="tab-content-info" dangerouslySetInnerHTML={{ __html: tab.field_description_wysiwyg.processed }} />
                    ) : (
                      null
                    )}
                    {tab.relationships.field_pool_info && tab.relationships.field_pool_info.map((pool,index) => (
                      <div className="pool-wrapper" key={index}>
                        <div className="uk-grid" data-uk-grid>
                          {pool.relationships.field_media_image ? (
                            <div className="uk-width-1-4@m">
                              <div className="press-image" style={{backgroundImage: `url(${pool.relationships.field_media_image.relationships.field_media_image.uri.url})`}} />
                            </div>
                          ) : (
                            null
                          )}
                          <div className="uk-width-expand">
                            {pool.field_title ? (
                              <h4>{pool.field_title}</h4>
                            ) : (
                              null
                            )}

                            <div className="uk-grid uk-child-width-1-2@m contact-info" >
                              {pool.relationships.field_contact_info && pool.relationships.field_contact_info.map((contact,key) => (
                                contact.__typename === 'paragraph__contact_address' ? (
                                  <div key={'paragraph__contact_address' + key}>
                                    <div className="location">
                                      {contact.field_address ? (
                                        <div className="address-line-1">{contact.field_address} (<a className="directions" href={"https://www.google.com/maps/dir/Current+Location/" + contact.field_address} target="_blank" rel="noreferrer">Get Directions</a>)</div>
                                      ) : (
                                        null
                                      )}
                                      {contact.field_address_line_2 ? (
                                        <div className="address-line-1">{contact.field_address_line_2} (<a className="directions" href={"https://www.google.com/maps/dir/Current+Location/" + contact.field_address_line_2} target="_blank" rel="noreferrer">Get Directions</a>)</div>
                                      ) : (
                                        null
                                      )}

                                    </div>
                                  </div>
                                ) : (
                                  contact.__typename === 'paragraph__contact_phone' ? (
                                    <div key={'paragraph__contact_phone' + key}>
                                      {contact.field_phone ? (
                                        <div className="phone">
                                          <a href={"tel:"+contact.field_phone}>{contact.field_phone}</a>
                                        </div>
                                      ) : (
                                        null
                                      )}
                                    </div>
                                  ) : (
                                    contact.__typename === 'paragraph__contact_custom_text' ? (
                                      <div key={'paragraph__contact_custom_text' + key}>
                                        <div className="time" dangerouslySetInnerHTML={{ __html: contact.field_description_wysiwyg.processed}} />
                                      </div>
                                    ) : (
                                      null
                                    )
                                  )
                                )
                              ))}
                            </div>
                            {pool.field_description_wysiwyg ? (
                              <div className="about" dangerouslySetInnerHTML={{ __html: pool.field_description_wysiwyg.processed}} />
                            ) : (
                              null
                            )}

                          </div>
                        </div>
                      </div>

                    ))}


                  </div>
                ))}
              </div>
            </div>
          </section>



          {currentPage.relationships.field_basic_flexible && currentPage.relationships.field_basic_flexible.map((layout,key) =>(
            layout.__typename === 'paragraph__agency_overview' ? (
              <section className="agency-overview" key="paragraph__agency_overview">
                <AgencyOverview content={layout} page={currentPage} orientation="right" />
              </section>
            ) : (
              layout.__typename === 'paragraph__repeating_icon_description_link' ? (
                <section className="login-find-activity-park" key="paragraph__repeating_icon_description_link">
                  <RepeatingIcons content={layout} />
                </section>
              ) : (
                layout.__typename === 'paragraph__overview_extended' ? (
                  <section className="agency-overview" key="paragraph__overview_extended">
                    <AgencyOverview content={layout} page={currentPage}  orientation="left"/>
                  </section>
                ) : (
                  null
                )
              )
            )
          ))}

        </div>
        </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!, $groupmenu: String, $landing: Int, $groupid: Int) {

    nodePage(id: { eq: $id }) {
      title
      path {
        alias
      }
      relationships {
        field_meta_data {
          ...paragraph__meta_dataFragment
        }
        field_basic_flexible {
          ... on paragraph__repeating_icon_description_link {
            id
            relationships {
              field_item {
                field_link {
                  title
                  uri
                }
                field_description
                field_title
                relationships {
                  field_icon {
                    uri {
                      url
                    }
                  }
                }
              }
            }
          }
          ... on paragraph__agency_overview {
            field_title
            field_description
            field_name
            field_position
            field_link {
              title
              uri
            }
            relationships {
              field_contact {
                ... on paragraph__contact_address {
                  field_address
                  field_address_line_2
                }
                ... on paragraph__contact_email {
                  field_email
                }
                ... on paragraph__contact_phone {
                  field_phone
                  field_title
                }
                ... on paragraph__contact_custom_text {
                  field_description_wysiwyg {
                    processed
                  }
                }
                ... on paragraph__contact_fax {
                  field_phone
                }
                ... on paragraph__contact_mobile_phone {
                  field_phone
                }
              }
              field_media_image {
                relationships {
                  field_media_image {
                    uri {
                      url
                    }
                  }
                }
              }
            }
          }
          ... on paragraph__overview_extended {
            field_title
            field_description_wysiwyg {
              value
              processed
            }
            field_name
            field_position
            field_link {
              title
              uri
            }
            relationships {
              field_contact {
                ... on paragraph__contact_address {
                  field_address
                  field_address_line_2
                }
                ... on paragraph__contact_email {
                  field_email
                }
                ... on paragraph__contact_phone {
                  field_phone
                  field_title
                }
              }
              field_media_image {
                relationships {
                  field_media_image {
                    uri {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
      drupal_internal__nid
      body {
        value
        processed
      }
    }

    allNodeContactPage(filter: {relationships: {group_content__group_content_type_4f1d2aba752bd: {elemMatch: {relationships: {gid: {drupal_internal__id: {eq: $groupid}}}}}}}) {
      edges {
        node {
          title
          path {
            alias
          }
        }
      }
    }

    allBlockContentPools(filter: {drupal_internal__id: {eq: 12}}) {
      edges {
        node {
          status
          info
          relationships {
            field_tab_content {
              field_title
              field_description_wysiwyg {
                value
                processed
              }
              relationships {
                field_pool_info {
                  field_description_wysiwyg {
                    value
                    processed
                  }
                  field_title
                  relationships {
                    field_media_image {
                      relationships {
                        field_media_image {
                          uri {
                            url
                          }
                        }
                      }
                    }
                    field_contact_info {
                      ... on paragraph__contact_address {
                        field_address_line_2
                        field_address
                      }
                      ... on paragraph__contact_custom_text {
                        field_description_wysiwyg {
                          value
                          processed
                        }
                      }
                      ... on paragraph__contact_phone {
                        field_phone
                        field_title
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    allNodeAlert(sort: {fields: field_weight, order: ASC}) {
      edges {
       ...node__alertEdgeFragment
      }
    }

    allMenuLinkContentMenuLinkContent(filter: {menu_name: {eq: $groupmenu}, enabled: {eq: true}}, sort: {fields: weight, order: ASC}) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          title
          link {
            title
            uri
          }
        }
      }
    }
    allNodeLandingPage(filter: {drupal_internal__nid: {eq: $landing}}) {
      edges {
        ...node__landing_pageEdgeFragment
      }
    }
  }
`
